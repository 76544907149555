<template>
  <tr>
    <td class="ultra-date-alerts">
      {{alert.dateToLocal()}}
    </td>
    <td>
      {{printNcIfNull(getInsertionOrder)}}
    </td>
    <td>
      {{printNcIfNull(propData['id'])}}
    </td>
    <td>
      {{printNcIfNull(propData['type_budget'])}}
    </td>
    <td>
      {{printNcIfNull(propData['revenue_type'])}}
    </td>
    <td>
      {{printNcIfNull(propData['revenue_value'])}}
    </td>
    <td>
      {{printNcIfNull(propData['supported business model'])}}
    </td>
  </tr>
</template>

<script>
import UltraDetailedMixin from './mixin/UltraDetailedMixin'

export default {
  mixins: [UltraDetailedMixin],
  name: 'UnsupportedBusinessModelDetailed',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    printNcIfNull (value) {
      return this.$isNotNullOrUndefined(value) ? value : 'NC'
    }
  },
  computed: {
    getInsertionOrder () {
      const ioField = this.$dspConfig[this.alert.getDspName()].ioFieldAlert
      return this.alert.group_key[ioField]
    }
  },
  watch: {
  }
}
</script>

<style>
  .ultra-date-alerts {
    width: 4em;
  }

  .temp_UnsupportedBusinessModelDetailed.fixed-headers td,
  .temp_UnsupportedBusinessModelDetailed.fixed-headers th {
    width: 11em !important;
  }
</style>
