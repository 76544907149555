/**
 * contain element and function needed in ultraDetailedComponent who are iterable
 */
export default {
  props: ['propData', 'alert', 'item', 'index', 'ckey'],
  methods: {
    generateBorderTable: function (index, item) {
      return { 'border-top-ultra': index === 0, 'border-bottom-ultra': index === (Object.keys(item).length - 1) }
    }
  }
}
